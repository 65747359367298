import React, { useState,useEffect } from "react";
import google from "../../../assests/images/google.svg";
import facebook from "../../../assests/images/facebook.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MiniLoader from "../../preloader/mini-preloader";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setHasSignIn } from "../../../redux/features/userSlice";
import countries from '../../../data/countries';


export default function SignupForm() {
  function navigate(url) {
    window.location.href = url;
  }

  const apiUrl = process.env.REACT_APP_API_URL;
  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [referralCodeFromURL, setReferralCodeFromURL] = useState("");
  const [agreeToPolicy, setAgreeToPolicy] = useState(false)

  const dispatch = useDispatch();

  async function auth() {
    const response = await fetch("https://klean-up-server-hz1y.onrender.com/v1/api/oauthResponse", {
      method: "post",
    });
    const data = await response.json();
    navigate(data.url);
  }

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    postalCode: "",
    phoneNumber: "",
    referralCode: "",
  });

  // Country code and number input
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");

  const handleCountryChange = (event) => {
    const selectedAbbreviation = event.target.value;
    const country = countries.find(
      (country) => country.abbreviation === selectedAbbreviation
    );
    setSelectedCountryCode(country.code);
    setFormData({ ...formData, phoneNumber: country.code });
  };

  const handlePhoneNumberChange = (event) => {
    setFormData({ ...formData, phoneNumber: event.target.value });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const crl = params.get('crl');
    if (crl) {
      setReferralCodeFromURL(crl);
      setFormData({ ...formData, referralCode: crl });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.fullname || !formData.email || !formData.password) {
      toast.error("❌ Please fill in all the fields to register.");
      setLoading(false);
      return;
    }

    // Validate password
    // const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(.{8,})$/;
    // if (!passwordRegex.test(formData.password)) {
    //   toast.error(
    //     "Password must contain at least one uppercase letter, one symbol, and be at least 8 characters long."
    //   );
    //   setLoading(false);
    //   return;
    // }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      setLoading(false);
      return;
    } else {
      setPasswordError("");
    }

    try {
      const response = await axios.post(
        `${apiUrl}/createCustomer`,
        formData
      );
      console.log("Registration successful:", response.data);
      toast.success("Registration successful!");
      dispatch(setHasSignIn(true));

      // Check if there's booking data in sessionStorage
      const bookingData = sessionStorage.getItem('bookingData');
      if (bookingData) {
        // If there's booking data, redirect back to booking page
        history('/book-cleaning');
      } else {
        // Otherwise, go to login
        setTimeout(() => {
          history("/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      toast.error(
        error.response.data.error ||
          "Registration failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center bg-blue-600">
      <div className="w-[97%] md:w-[500px]">
        <div className="flex justify-center ">
          <p className="text-white text-[30px] font-medium  mt-[100px] mb-[45px] md:mb-[80px]">
            FreshMclean
          </p>
        </div>
        <ToastContainer />
        <div className="mb-[100px]">
          {/* <div class="flex justify-start mx-2">
            <Link to='/login'>
              <button className='bg-white px-3 py-2.5 text-sm md:text-base font-medium text-gray-800 rounded-lg mb-2'>Login</button>
            </Link>
          </div> */}

          <form onSubmit={handleSubmit}>
            <div className="bg-white shadow-md flex flex-col p-5 rounded-md mx-2">
              <label className="mb-1 text-[14px]">Full Name</label>
              <input
                type="text"
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
                placeholder="Enter your full name"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              <label className="mb-1 text-[14px]">Password</label>
              <div className="mb-8 flex flex-col">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password must contain 8 characters"
                  value={formData.password}
                  onChange={handleChange}
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                />
                <div className="ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div>

              {/* Confirm Password Field */}
              <label className="mb-1 text-[14px]">Confirm Password</label>
              <div className="mb-8 flex flex-col">
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                />
                <div className="ml-auto -mt-7 pr-3 cursor-pointer">
                  {showPassword ? (
                    <AiFillEyeInvisible onClick={togglePasswordVisibility} />
                  ) : (
                    <AiFillEye onClick={togglePasswordVisibility} />
                  )}
                </div>
              </div>
              {passwordError && (
                <span className="text-red-500 text-sm mt-1">
                  {passwordError}
                </span>
              )}
              {!referralCodeFromURL && (
                <>
                  <label className="mb-1 text-[14px]">
                    Referral Code(Optional)
                  </label>
                  <input
                    type="text"
                    name="referralCode"
                    value={formData.referralCode}
                    onChange={handleChange}
                    placeholder="Enter your referral code"
                    className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
                  />
                </>
              )}
              <label className="mb-1 text-[14px] block">Phone Number</label>
              <div className="flex items-center border border-[#CECECE] rounded-md bg-[#FAFAFA] py-2 px-3">
                {/* Country Select Dropdown */}
                <select
                  onChange={handleCountryChange}
                  className=" mr-2 text-[14px] bg-transparent w-[150px] outline-none font-medium"
                >
                  {countries.map((country) => (
                    <option key={country.abbreviation} value={country.abbreviation}>
                      {country.name} ({country.code})
                    </option>
                  ))}
                </select>

                {/* Phone Number Input */}
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter your phone number"
                  className="flex-grow placeholder:text-[#a1a0a0] bg-[#FAFAFA] text-[14px] focus:outline-none"
                />
              </div>
              <label className="mb-1 mt-2 text-[14px]">Postal Code</label>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder="Enter your postal code"
                className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0]  bg-[#FAFAFA] py-2 px-5 mb-5"
              />
              {/* Privacy Policy Checkbox */}
              <div className="flex items-center mb-4">
                <div className="relative flex items-center">
                  <input
                    type="checkbox"
                    id="privacy-policy"
                    onChange={(e) => setAgreeToPolicy(e.target.checked)}
                    className="opacity-0 absolute h-5 w-5 cursor-pointer"
                  />
                  <div className={`border border-gray-300 rounded-md h-5 w-5 flex flex-shrink-0 justify-center items-center mr-2 ${agreeToPolicy ? 'bg-blue-500 border-blue-500' : 'bg-white'}`}>
                    {agreeToPolicy && (
                      <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                      </svg>
                    )}
                  </div>
                </div>
                <label htmlFor="privacy-policy" className="text-sm text-gray-700 cursor-pointer">
                  I have read and agreed to the <Link to="/privacy-policy" className="text-blue-500 hover:underline">privacy policy</Link>
                </label>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className={`${!agreeToPolicy ? 'bg-gray-400' : 'bg-primary-sky'} text-white py-3 rounded-md text-center w-full transition-colors`}
                disabled={loading || !agreeToPolicy}
              >
                {loading ? <MiniLoader /> : "Signup"}
              </button>

              <hr class="mt-8 mb-4" />

              <p class="text-gray-600 text-sm text-center font-medium mb-3">
                Or sign in with
              </p>

              <div className="flex text-[12px] max-sm:text-[16px] gap-x-2 gap-y-5 max-sm:flex-col max-sm:mx-2">
                <button type="button"
                  className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md flex gap-x-2 justify-center items-center"
                  onClick={() => auth()}
                >
                  <img src={google} alt="google" />
                  Signup with google
                </button>
                <button className="flex-1 bg-[#F4F4F4] px-2 py-3 rounded-md  flex gap-x-2 max-sm:justify-center items-center">
                  <img src={facebook} alt="facebook" />
                  Signup with Facebook
                </button>
              </div>

              <p className="text-center text-slate-600 text-sm font-medium mt-8">Already have an account? <Link className="text-blue-600" to='/login'>Sign in here</Link> </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
