import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Pricings() {
  const { t } = useTranslation();
  const [frequencies, setFrequencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const basePrice = 54;
  const calculatePrice = (discountPercentage) => {
    return (basePrice * (1 - discountPercentage / 100)).toFixed(2);
  };

  // UI elements that don't come from the API
  const pricingUIElements = {
    "One time": {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      ),
      description: "Perfect for a thorough clean without commitment",
      features: [
        "1 Kitchen",
        "1 Living Room",
        "1 Bathroom",
        "Flexible scheduling",
        "No contract required"
      ]
    },
    "Weekly": {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      ),
      description: "Regular freshness for busy households",
      features: [
        "1 Kitchen",
        "1 Living Room",
        "1 Bathroom",
        "Priority scheduling",
        "Consistent cleaning team"
      ]
    },
    "Fortnightly": {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
      ),
      description: "Balanced approach to home cleaning",
      features: [
        "1 Kitchen",
        "1 Living Room",
        "1 Bathroom",
        "Dedicated cleaning team",
        "Detailed cleaning checklist"
      ]
    },
    "Monthly": {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
        </svg>
      ),
      description: "Deep clean for low-maintenance homes",
      features: [
        "1 Kitchen",
        "1 Living Room",
        "1 Bathroom",
        "Extended cleaning time",
        "Special attention to details"
      ]
    }
  };

  useEffect(() => {
    const fetchFrequencies = async () => {
      try {
        const response = await fetch(`${apiUrl}/getAllFrequencies`);
        const result = await response.json();
        if (result.success) {
          setFrequencies(result.data);
        }
      } catch (error) {
        console.error('Error fetching frequencies:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFrequencies();
  }, [apiUrl]);

  if (loading) {
    return <div className="text-center py-20">Loading pricing options...</div>;
  }

  return (
    <div className="bg-primary-benefit flex justify-center py-20 w-full">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="text-center flex items-center justify-center flex-col max-xs:px-2">
          <p className="text-4xl font-semibold mb-4">
            {t('home.services.pricing')}
          </p>
          <p className="text-slate-800 inline-block text-sm md:text-base">
            {t('home.services.pricingText')}
          </p>
        </div>

        <section>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
              {frequencies.map((frequency) => {
                const uiElements = pricingUIElements[frequency.frequencyName];
                return (
                  <div
                    key={frequency._id}
                    className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 transition-transform hover:scale-105 hover:shadow-lg flex flex-col h-full"
                  >
                    <div className="pt-6 pb-3 px-3 flex flex-col items-center">
                      {uiElements.icon}
                      <h3 className="mt-4 text-xl font-semibold text-gray-900">
                        {frequency.frequencyName}
                      </h3>
                      <div className="mt-2 flex items-baseline">
                        <span className="text-3xl font-extrabold text-blue-500">{calculatePrice(frequency.frequencyDiscount)}€</span>
                        <span className="ml-1 text-sm text-gray-500">per visit</span>
                      </div>
                      {frequency.frequencyDiscount > 0 && (
                        <span className="mt-2 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                          {frequency.frequencyDiscount}% discount
                        </span>
                      )}
                      <p className="mt-3 text-sm text-gray-600 text-center">
                        {uiElements.description}
                      </p>
                    </div>

                    <div className="px-3 flex-grow">
                      <ul className="mt-6 space-y-3">
                        {uiElements.features.map((feature, index) => (
                          <li key={index} className="flex items-start">
                            <div className="flex-shrink-0">
                              <svg className="h-5 w-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <p className="ml-3 text-sm text-gray-600">{feature}</p>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="px-3 pb-4 mt-6">
                      <Link
                        to={`/book-cleaning?frequency=${frequency.frequencyName.toLowerCase().replace(' ', '-')}`}
                        className="block w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-md text-center transition duration-150 ease-in-out text-sm"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
