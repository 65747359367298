import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimeSelector = ({ onDateTimeSelected, initialDateTime }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    // Available time slots
    const timeSlots = [
        '7:30', '8:00', '9:00', '10:00', '11:00', '12:00',
        '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
        '19:00', '20:00'
    ];

    // Set initial values only once when component mounts
    useEffect(() => {
        if (initialDateTime && !selectedDate && !selectedTime) {
            const date = new Date(initialDateTime);
            setSelectedDate(date);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            setSelectedTime(`${hours}:${minutes}`);
        }
    }, []); // Empty dependency array means this only runs once on mount

    // Formatting for displaying month and year
    const formatMonthHeader = (date) => {
        return date.toLocaleString('default', { month: 'long' });
    };

    // When either date or time changes, update the parent component
    const handleSelection = (date, time) => {
        if (date && time) {
            const [hours, minutes] = time.split(':').map(Number);
            const dateTime = new Date(date);
            dateTime.setHours(hours, minutes);
            onDateTimeSelected(dateTime);
        }
    };

    // Update selections and notify parent
    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (selectedTime) {
            handleSelection(date, selectedTime);
        }
    };

    // Update time selection and notify parent
    const handleTimeChange = (time) => {
        setSelectedTime(time);
        if (selectedDate) {
            handleSelection(selectedDate, time);
        }
    };

    return (
        <div className="my-8">
            <h2 className="text-lg text-gray-500 font-medium mb-6">SELECT DATE AND TIME</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Calendar */}
                <div>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        inline
                        monthsShown={1}
                        minDate={new Date()}
                        calendarClassName="w-full"
                        dayClassName={date =>
                            date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth()
                                ? 'bg-blue-600 text-white rounded hover:bg-blue-700'
                                : undefined
                        }
                    />
                </div>

                {/* Time slots */}
                <div>
                    <div className="grid grid-cols-3 gap-2">
                        {timeSlots.map(time => (
                            <button type="button"
                                key={time}
                                className={`py-2 px-4 border rounded-md ${selectedTime === time
                                        ? 'bg-blue-600 text-white border-blue-600'
                                        : 'border-gray-200 hover:border-blue-400'
                                    }`}
                                onClick={() => handleTimeChange(time)}
                            >
                                {time}
                            </button>
                        ))}
                    </div>

                    {/* <div className="mt-4">
                        <button type="button" className="py-2 px-4 bg-gray-100 rounded-md text-gray-700 hover:bg-gray-200">
                            You can start at any time
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default DateTimeSelector;