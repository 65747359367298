import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BookCleaning from "./book";

// const stripePromise = loadStripe(
//   "pk_test_51PCEkpJMZmoyPxozoXx3R801Uw8kNlr9otbxh8XmLwanKYq8FeWRlo44pqUkDKsk7tRXVVEiIxbMWOTL0CUPe2pX00xx3erHFc"
// );
// const stripePromise = loadStripe(`${process.env.REACT_STRIPE_PUBLIC_KEY}`);
const stripePromise = loadStripe(
  "pk_test_51PCEkpJMZmoyPxozoXx3R801Uw8kNlr9otbxh8XmLwanKYq8FeWRlo44pqUkDKsk7tRXVVEiIxbMWOTL0CUPe2pX00xx3erHFc"
);

const BookCleaningWrapper = () => (
  <Elements stripe={stripePromise}>
    <BookCleaning />
  </Elements>
);

export default BookCleaningWrapper;
