import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: cardElement },
    });

    if (error) {
      console.error(error.message);
      toast.error(`Error: ${error.message}`)
    } else {
      console.log("Payment successful:", paymentIntent);
      toast.success("Payment Successful!");
      navigate('/dashboard')
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg"
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Card Details
          </label>
          <div className="border border-gray-300 p-3 rounded-md bg-gray-50">
            <CardElement className="w-full" />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 hover:bg-blue-600 px-4 py-2.5 w-full text-white text-base font-medium rounded-md transition disabled:bg-gray-400"
          disabled={!stripe}
        >
          Pay Now
        </button>
      </form>

      <ToastContainer />
    </>
  );
};

export default CheckoutForm;