import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsArrowLeft } from "react-icons/bs";
import MiniLoader from '../../../preloader/mini-preloader'
import Preloader from '../../../preloader/preloader'
import { loadStripe } from '@stripe/stripe-js';
import Paypal from '../../../modalView/Paypal';

import { useTranslation } from 'react-i18next'

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completingOrder, setCompletingOrder] = useState(false);
  const [, setProduct] = useState(null);

  const { t } = useTranslation();

  const apiUrl = process.env.REACT_APP_API_URL;
  // const token = localStorage.getItem("token");

  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [selectedFilter, setSelectedFilter] = useState("This year");
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    from: "",
    to: "",
  });

  useEffect(() => {
    const fetchOrders = async () => {
      const token = localStorage.getItem("token");

      // Ensure the token exists
      if (!token) {
        setError("Authorization token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/customersAllOrders/`, {
          headers: {
            Authorization: `Bearer ${token.trim()}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        // Check if the error has a response
        if (error.response) {
          // Handle specific HTTP errors
          const status = error.response.status;
          if (status === 401) {
            setError("Unauthorized. Please log in again.");
          } else if (status === 403) {
            setError("Forbidden. You don't have access to these orders.");
          } else if (status === 404) {
            setError("Orders not found.");
          } else {
            setError("Failed to fetch orders. Please try again later.");
          }
        } else {
          // Handle network or other errors
          setError("Network error. Please check your connection.");
        }
      } finally {
        setLoading(false);
      }
    };


    fetchOrders();
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      applyFilter();
    }
  }, [orders]);

  useEffect(() => {
    applyFilter();
  }, [selectedFilter, customDateRange]);

  const applyFilter = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    let filtered = orders;

    switch (selectedFilter) {
      case "Today":
        filtered = orders.filter(
          (order) =>
            new Date(order.desireDate).toDateString() === today.toDateString()
        );
        break;
      case "This week":
        filtered = orders.filter(
          (order) =>
            new Date(order.desireDate) >= startOfWeek &&
            new Date(order.desireDate) <= today
        );
        break;
      case "This month":
        filtered = orders.filter(
          (order) =>
            new Date(order.desireDate) >= startOfMonth &&
            new Date(order.desireDate) <= today
        );
        break;
      case "This year":
        filtered = orders.filter(
          (order) =>
            new Date(order.desireDate) >= startOfYear &&
            new Date(order.desireDate) <= today
        );
        break;
      case "Choose custom date":
        const from = new Date(customDateRange.from);
        const to = new Date(customDateRange.to);
        filtered = orders.filter(
          (order) =>
            new Date(order.desireDate) >= from &&
            new Date(order.desireDate) <= to
        );
        break;
      default:
        break;
    }

    setFilteredOrders(filtered);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setSelectedFilter(value);

    if (value === "Choose custom date") {
      setIsCustomDateModalOpen(true);
    }
  };

  const handleCustomDateFilter = () => {
    setIsCustomDateModalOpen(false);
    applyFilter();
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // State for live search
  const itemsPerPage = 10;

  // Filter orders based on the search query (live search)
  const searchedOrders = filteredOrders.filter((order) =>
    order._id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(searchedOrders.length / itemsPerPage);
  const currentOrders = searchedOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query
    setCurrentPage(1); // Reset to page 1 when search changes
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "approved":
        return "bg-green-100 text-green-700";
      case "incomplete":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  const completeOrder = async (
    orderId,
    serviceId,
    serviceQuantity,
    paymentMethod
  ) => {
    setCompletingOrder(true);
    const token = localStorage.getItem("token");
    if (paymentMethod === "Stripe") {
      try {
        // Fetch product details
        const productResponse = await axios.get(
          `${apiUrl}/service/${serviceId}`
        );
        if (productResponse.data) {
          setProduct(productResponse.data);

          const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

          const body = {
            serviceName: productResponse.data.serviceName,
            servicePrice: productResponse.data.servicePrice,
            serviceImage: productResponse.data.serviceImage,
            serviceQuantity,
            orderId,
          };

          const headers = {
            "Content-Type": "application/json",
          };

          const response = await fetch(`${apiUrl}/checkoutStripe`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
          });

          const session = await response.json();

          if (!response.ok) {
            throw new Error(session.error || "Something went wrong");
          }

          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });

          if (result.error) {
            console.log(result.error.message);
          }
        } else {
          throw new Error("Failed to fetch product details");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setCompletingOrder(false);
      }
    } else if (paymentMethod === "PayPal") {
      setCompletingOrder(false);
      return <Paypal />;
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // console.log(orders);

  return (
    <div className="bg-white p-6 mt-20 mb-6 rounded shadow-md">
      <h2 className="text-2xl font-bold mb-6">{t('dashboard.orderHistory')}</h2>
      {!selectedOrder && (
        <>
          <div className="flex items-center justify-between">
            <input
              type="search"
              className="px-2 py-2 border border-gray-300 rounded-lg text-sm max-w-md"
              placeholder="Search history"
              value={searchQuery}
              onChange={handleSearchChange}
            />

            <select
              className="px-2 py-2.5 border border-gray-300 text-sm rounded-lg"
              onChange={handleFilterChange}
              value={selectedFilter}
            >
              <option>Today</option>
              <option>This week</option>
              <option>This month</option>
              <option>This year</option>
              <option>Choose custom date</option>
            </select>
          </div>
        </>
      )}

      {!selectedOrder ? (
        <div>
          <div className="overflow-x-auto w-full">
            <table className="table-auto w-full border-collapse border border-gray-200 mt-5">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2.5 border text-left">{t('dashboard.orderId')}</th>
                  <th className="px-4 py-2.5 border text-left">{t('dashboard.desireDate')}</th>
                  <th className="px-4 py-2.5 border text-left">{t('dashboard.orderType')}</th>
                  <th className="px-4 py-2.5 border text-left">{t('dashboard.status')}</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.length > 0 ? (
                  currentOrders.map((order) => (
                    <tr
                      key={order._id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleOrderClick(order)}
                    >
                      <td className="px-4 py-3 border">{order._id}</td>
                      <td className="px-4 py-3 border">
                        {new Date(order.desireDate).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-3 border capitalize">
                        {order.orderType}
                      </td>
                      <td className="px-4 py-3 border">
                        <span className="px-2 py-1 rounded-lg uppercase text-sm font-medium bg-blue-100 text-blue-600">
                          {order.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center py-4">
                        {t('dashboard.noOrder')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Footer Section */}
            <div className="flex items-center justify-between mt-4">
              {/* Left - Showing X of Y */}
              <div className="text-sm text-gray-600">
                {t('dashboard.showing')} {currentOrders.length} of {filteredOrders.length}
              </div>

              {/* Right - Pagination Controls */}
              <div className="flex items-center space-x-2">
                <button
                  className="px-3 py-1 border hover:bg-blue-100 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  {t('dashboard.prev')}
                </button>

                <span className="text-sm text-gray-600">
                  Page {currentPage} of {totalPages}
                </span>

                <button
                  className="px-3 py-1 border hover:bg-blue-100 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  {t('dashboard.next')}
                </button>
              </div>
            </div>
          </div>

          {/* Custom Date Range Modal */}
          {isCustomDateModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
                {/* Close Icon */}
                <button
                  onClick={() => setIsCustomDateModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
                  aria-label="Close"
                >
                  &times;
                </button>

                <h3 className="text-lg font-bold mb-4">
                  Choose Custom Date Range
                </h3>
                <div className="flex flex-col space-y-4">
                  <label>
                    From:
                    <input
                      type="date"
                      value={customDateRange.from}
                      onChange={(e) =>
                        setCustomDateRange({
                          ...customDateRange,
                          from: e.target.value,
                        })
                      }
                      className="mt-1 p-2 border border-gray-300 rounded w-full"
                    />
                  </label>
                  <label>
                    To:
                    <input
                      type="date"
                      value={customDateRange.to}
                      onChange={(e) =>
                        setCustomDateRange({
                          ...customDateRange,
                          to: e.target.value,
                        })
                      }
                      className="mt-1 p-2 border border-gray-300 rounded w-full"
                    />
                  </label>
                  <button
                    onClick={handleCustomDateFilter}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg font-semibold"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button
            className="mb-4 text-blue-500 flex items-center"
            onClick={() => setSelectedOrder(null)}
          >
            <BsArrowLeft className="mr-1" />
            Back to Order History
          </button>
          <div className="border p-4 rounded-lg bg-gray-50">
            <h3 className="text-lg font-bold mb-4">Order Details</h3>
            <p className="py-1">
              <strong>Order Number:</strong> {selectedOrder._id}
            </p>
            <p className="py-1">
              <strong>Order Type:</strong> {selectedOrder.orderType}
            </p>
            <p className="py-1">
              <strong>Date:</strong>{" "}
              {new Date(selectedOrder.desireDate).toLocaleDateString()}
            </p>
            <p className="py-1">
              <strong>Time:</strong> {selectedOrder.desireTime}
            </p>
            <p className="py-1">
              <strong>Order Range:</strong> {selectedOrder.orderRange}
            </p>
            <p className="py-1">
              <strong>Service Quantity:</strong> {selectedOrder.serviceQuantity}
            </p>
            <p className="py-1">
              <strong>Post Code:</strong> {selectedOrder.address.postCode}
            </p>
            <p className="py-1">
              <strong>Street Name:</strong> {selectedOrder.address.streetName}
            </p>
            <p className="py-1">
              <strong>Payment Method:</strong> {selectedOrder.payment.method}
            </p>
            <p className="py-1">
              <strong>City:</strong> {selectedOrder.address.city}
            </p>
            <p className="py-1">
              <strong>House Number:</strong> {selectedOrder.address.houseNumber}
            </p>
            <p className="py-1">
              <strong>Building:</strong> {selectedOrder.address.building}
            </p>
            <p className="py-1">
              <strong>Entrance Number:</strong>{" "}
              {selectedOrder.address.entranceNumber}
            </p>
            <p className="py-1">
              <strong>Customer Phone Number:</strong>{" "}
              {selectedOrder.address.customerPhoneNumber}
            </p>
            <p className="py-1">
              <strong>Additional Information:</strong>{" "}
              {selectedOrder.address.additonalInformation}
            </p>
            <p
              className={`py-1 px-2 rounded ${getStatusClass(
                selectedOrder.status
              )}`}
            >
              <strong>Status:</strong> {selectedOrder.status}
            </p>
            {selectedOrder.status === "incomplete" && (
              <button
                onClick={() =>
                  completeOrder(
                    selectedOrder._id,
                    selectedOrder.serviceId,
                    selectedOrder.serviceQuantity,
                    selectedOrder.payment.method
                  )
                }
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                disabled={completingOrder}
              >
                {completingOrder ? <MiniLoader /> : "Complete Order Now"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
