import { useState } from 'react'
import MiniLoader from '../../../components/preloader/mini-preloader';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import CustomerNavigation from "../../../components/customer-component/customerNav/nav";
import Footer from "../../../components/home-ui-components/footer";
import { useSearchParams, useNavigate } from "react-router-dom";

const VerifyCode = () => {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!code) {
            toast.error("Please enter the OTP code");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/verify-otp/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, otp: code }),
            });

            if (!response.ok) {
                throw new Error("Invalid OTP. Please try again.");
            }

            // Navigate to /change-password with email in URL params
            navigate(`/change-password?email=${encodeURIComponent(email)}`);
        } catch (error) {
            console.error("OTP verification failed:", error);
            toast.error("OTP verification failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <CustomerNavigation />
            <div className="flex justify-center bg-blue-600">
                <div className="w-[97%] md:w-[500px]">
                    <div className="flex justify-center ">
                        <p className="text-white text-[30px] font-medium  mt-[100px] mb-[45px] md:mb-[80px]">
                            FreshMclean
                        </p>
                    </div>
                    <div className="mb-[100px]">
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white flex flex-col p-5 rounded-md mx-2">
                                <ToastContainer />

                                <div className="space-y-4">
                                    <p className='text-slate-900 text-center font-medium text-md'>Check your email for verification code</p>
                                    <div>
                                        <label className="mb-1 font-medium text-sm block">
                                            Code
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                            placeholder="Enter your OTP code"
                                            className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="bg-primary-sky text-white py-3 rounded-md text-center mt-3"
                                    disabled={loading}
                                >
                                    {loading ? <MiniLoader /> : "Verify OTP"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default VerifyCode;