import Navigation from "../components/nav";
import Footer from "../components/home-ui-components/footer";

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <Navigation />

            <div className="max-w-3xl mx-auto px-4 py-12">
                <header className="mb-10 text-center">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4">Privacy Policy</h1>
                    <p className="text-gray-600">Effective date: March 16, 2025</p>
                </header>

                <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
                    <p className="text-gray-700 mb-4 text-sm md:text-base">
                        At FreshMClean, your privacy is important to us. This Privacy Policy outlines how we collect,
                        use, and protect your personal information when you engage with our services in Berlin
                    </p>
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We collect personal information to provide and improve our cleaning services. This includes:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className='text-sm md:text-base'> <span className="font-semibold">Contact Information:</span> Name, phone number, email address, and physical address for
                                service coordination</li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Payment Information:</span> Bank details or payment method details (processed securely
                                through third-party payment providers).</li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Service Details:</span> Information about the spaces to be cleaned and any special requests.</li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Usage Data:</span> Data about your interaction with our website or app (if applicable), such as
                                IP addresses, browser type, and time spent on our site</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We use your personal data for the following purposes:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className='text-sm md:text-base'> To schedule and deliver cleaning services.</li>
                            <li className='text-sm md:text-base'>To communicate updates or respond to inquiries.</li>
                            <li className='text-sm md:text-base'>To process payments securely.</li>
                            <li className='text-sm md:text-base'>To improve our services through feedback or analytics.</li>
                            <li className='text-sm md:text-base'>To comply with legal obligations.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">3. Sharing Your Information</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We do not sell or rent your personal information. We may share your data with:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className='text-sm md:text-base'> <span className="font-semibold">Service Providers:</span> Third-party professionals assisting in service delivery or payment processing</li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Legal Authorities:</span> When required by law or to protect our legal rights.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Data Security</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We prioritize the security of your data by using industry-standard encryption and secure data
                            storage methods. While we strive to protect your data, no method of transmission over the
                            internet or electronic storage is 100% secure.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Your Rights</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            Under applicable privacy laws, including the General Data Protection Regulation (GDPR), you
                            have the right to:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className='text-sm md:text-base'> Access your personal data.</li>
                            <li className='text-sm md:text-base'>Request corrections to inaccurate or incomplete data.</li>
                            <li className='text-sm md:text-base'>Request deletion of your data, where legally permissible.</li>
                            <li className='text-sm md:text-base'>Withdraw consent for data processing.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Data Retention</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We retain your data only as long as necessary to provide our services and comply with legal
                            obligations.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Cookies and Tracking</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            If you use our website, cookies may be used to enhance your experience. You can control or
                            disable cookies through your browser settings
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Updates to This Privacy Policy</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            We may update this policy occasionally to reflect changes in our practices or legal requirements.
                            Any updates will be posted on our website with a revised effective date.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">9. Contact Us</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            If you have questions or concerns about this Privacy Policy, please contact us at:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className='text-sm md:text-base'> <span className="font-semibold">Email:</span> info@freshmclean.de</li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Phone:</span> </li>
                            <li className='text-sm md:text-base'> <span className="font-semibold">Address:</span>  FreshMClean, Berlin, Germany</li>
                        </ul>
                    </section>
                </div>

                <div className="text-center text-gray-600 text-sm mt-8">
                    <p>Thank you for trusting FreshMClean with your cleaning needs and your privacy.</p>
                    {/* <p className="mt-2 font-medium">privacy@yourcompany.com</p> */}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;