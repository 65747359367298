// eslint-disable-next-line
import Home from "./pages/home";
import Mission from "./pages/about";
import Question from "./pages/question";
import Review from "./pages/review";
import Price from "./pages/price";
// import Schedule from "./pages/schedule";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CustomerType from "./pages/registration/customerType";
import BecomeCleaner from "./pages/becomeCleaner";
import SignUp from "./pages/registration/auth/signUp";
import Login from "./pages/registration/auth/login";
import CustomerHome from "./pages/home-dashboard/customer-home";
import CustomerProfile from "./pages/home-dashboard/profile";
import PaymentSuccess from "../src/components/modalView/paymentSuccess"
import PaymentFailure from "./components/modalView/paymentFailure";
import CleanerRegForm from './pages/clearnerRegForm'
import PricingDetails from './pages/pricing/details'
import ScrollToTop from "../src/components/scrolltotop"
import ResetPassword from "./pages/registration/auth/reset";
import VerifyCode from './pages/registration/auth/verify';
import ChangePassword from './pages/registration/auth/change-password'
import ComingSoon from './pages/comingsoon'
import PrivacyPolicy from "./pages/privacypolicy";
import TermsOfService from "./pages/termsofservice";
import BookCleaningWrapper from "./pages/bookcleaningwrapper";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/review" element={<Review />} />
          <Route path="/faqs" element={<Question />} />
          <Route path="/price" element={<Price />} />
          <Route path="/book-cleaning" element={<BookCleaningWrapper />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/pricing/:slug" element={<PricingDetails />} />
          <Route path="/about" element={<Mission />} />
          {/* <Route path="/contact" element={<Schedule />} /> */}
          <Route path="/authType" element={<CustomerType />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup?crl=${}" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/verify" element={<VerifyCode />} />
          <Route path="/change-password" element={<ChangePassword />} />
          {/* <Route path="/dashboard" element={<CustomerDashBoard /> } /> */}
          <Route path="/membership" element={<BecomeCleaner />} />
          <Route path="/dashboard" element={<CustomerHome />} />
          <Route path="/dashboard/profile" element={<CustomerProfile />} />
          <Route path="/paymentsuccess/:orderId" element={<PaymentSuccess />} />
          <Route path="/paymentfailure" element={<PaymentFailure />} />
          <Route path="/cleanerRegForm" element={<CleanerRegForm />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
