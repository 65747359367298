import Navigation from "../components/nav";
import QuestionBg from "../assests/images/question-bg.png";
import FAQs from "../components/home-ui-components/faqs";
import Footer from "../components/home-ui-components/footer";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const faqData = [
  {
    id: 1,
    question: "What services does FreshMClean provide?",
    answer: "We specialize in cleaning services for homes, offices, and schools across Berlin. Our team ensures every space is left spotless, using eco-friendly products and professional techniques",
    category: "General"
  },
  {
    id: 2,
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards, PayPal, and bank transfers. For enterprise customers, we also offer invoice-based payment options.",
    category: "Payments"
  },
  {
    id: 3,
    question: "Can I change my subscription plan?",
    answer: "Yes, you can upgrade or downgrade your plan at any time through your account settings. Changes will be prorated for the remainder of your billing cycle.",
    category: "Subscriptions"
  },
  {
    id: 4,
    question: "How do I reset my password?",
    answer: "Click on the 'Forgot Password' link on the login page. We'll send you an email with instructions to reset your password securely.",
    category: "General"
  },
  {
    id: 5,
    question: "What is your refund policy?",
    answer: "We offer a 30-day money-back guarantee on all our plans. If you're not satisfied, you can request a refund through your account page or by contacting our support team.",
    category: "Payments"
  },
  {
    id: 6,
    question: "How do I cancel my subscription?",
    answer: "You can cancel your subscription anytime from your account settings. Your service will continue until the end of your current billing period.",
    category: "Subscriptions"
  },
  {
    id: 7,
    question: "Do you offer technical support?",
    answer: "Yes, our technical support team is available 24/7. You can reach us through live chat, email, or phone support depending on your subscription tier.",
    category: "Services"
  },
  {
    id: 8,
    question: "How secure is my data?",
    answer: "We use industry-standard encryption and security practices to protect your data. All information is stored in secure, SOC 2 compliant data centers with regular security audits.",
    category: "Security"
  },
  {
    id: 9,
    question: "How do I book a cleaning service?",
    answer: "Booking is simple! Contact us via our website, phone, or email to schedule a cleaning. Let us know the type of space, preferred time, and any specific requirements.",
    category: "General"
  },
  {
    id: 10,
    question: "Do you use eco-friendly products?",
    answer: "Yes, we prioritize the health of our clients and the environment by using eco-friendly and non-toxic cleaning products.",
    category: "General"
  },
  {
    id: 11,
    question: "Which areas in Berlin do you serve?",
    answer: "We provide services across Berlin, including popular neighborhoods like Prenzlauer Berg, Charlottenburg, and other surrounding areas.",
    category: "General"
  },
  {
    id: 12,
    question: "Can I customize my cleaning plan?",
    answer: "Absolutely! Whether you need a one-time deep cleaning, regular maintenance, or specific areas cleaned, we'll tailor our services to suit your needs.",
    category: "General"
  },
  {
    id: 13,
    question: "Are your cleaners professionally trained?",
    answer: "Yes, our team consists of highly trained, experienced professionals committed to delivering quality cleaning services.",
    category: "General"
  },
  {
    id: 14,
    question: "Is FreshMClean insured?",
    answer: "Yes, FreshMClean is fully insured, providing peace of mind to all our clients.",
    category: "General"
  },
  {
    id: 15,
    question: "Do I need to provide cleaning supplies or equipment?",
    answer: "Yes, cleaning supplies will need to be provided by you for our services.",
    category: "General"
  }
];

export default function Question() {
  const [activeCategory, setActiveCategory] = useState('All');
  const [displayedFAQs, setDisplayedFAQs] = useState([]);
  const [expandedId, setExpandedId] = useState(null);

  const { t } = useTranslation();

  const categories = ['All', ...new Set(faqData.map(item => item.category))];

  useEffect(() => {
    if (activeCategory === 'All') {
      setDisplayedFAQs(faqData);
    } else {
      setDisplayedFAQs(faqData.filter(item => item.category === activeCategory));
    }
  }, [activeCategory]);

  const toggleQuestion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <div className="overflow-hidden">
      <div>
        <Navigation />
      </div>
      <div
        className="h-[350px]"
        style={{
          backgroundImage: ` url(${QuestionBg})`,
        }}
      >
        <div className="question-container h-[350px] flex justify-center items-center font-helvetica-neue">
          <div className="text-white text-center">
            <h1 className="text-3xl mb-2">{t('faq.title')}</h1>
            <p className="max-w-[430px] text-md mt-2">
              {t('faq.subtitle')}
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-8 text-blue-500">Frequently Asked Questions</h1>

        {/* Categories */}
        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${activeCategory === category
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-blue-100'
                }`}
            >
              {category}
            </button>
          ))}
        </div>

        {/* FAQ Items */}
        <div className="space-y-4">
          {displayedFAQs.length > 0 ? (
            displayedFAQs.map((faq) => (
              <div
                key={faq.id}
                className="border border-gray-200 rounded-lg overflow-hidden transition-shadow"
              >
                <button
                  className="w-full text-left p-4 focus:outline-none flex justify-between items-center"
                  onClick={() => toggleQuestion(faq.id)}
                >
                  <h3 className="font-medium text-gray-800">{faq.question}</h3>
                  <span className={`text-blue-500 transition-transform ${expandedId === faq.id ? 'rotate-180' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </span>
                </button>

                {expandedId === faq.id && (
                  <div className="p-4 pt-3 text-gray-600 bg-gray-50">
                    <p>{faq.answer}</p>
                    <div className="flex mt-2">
                      <span className="inline-block px-2 py-1 text-xs font-medium text-blue-500 bg-blue-100 rounded-full">
                        {faq.category}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No FAQs found in this category.
            </div>
          )}
        </div>
      </div>

      <div className='py-10 text-center text-md'>
          <h1 className='text-slate-800'>Can't find the answer to your question? Contact us at <br /> <a href="mailto:info@freshmclean.com" target='_blank' className='cursor-pointer text-blue-500 font-medium'>info@freshmclean.com</a></h1>
      </div>
    
      <div>
        <Footer />
      </div>
    </div>
  );
}
