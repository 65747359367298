import Navigation from "../components/nav";
import { useState } from "react";
import Footer from "../components/home-ui-components/footer";

const ComingSoon = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Thank you! We'll notify ${email} when we launch.`);
        setEmail('');
    };

    return (
        <>
            <Navigation />
            
            <div className="mx-auto px-4 py-16 flex flex-col items-center justify-center text-center">
                <div className="w-[90%] md:max-w-3xl mx-auto p-8 md:p-12">
                    <h1 className="text-4xl md:text-7xl font-bold text-black mb-6">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Coming</span> Soon
                    </h1>

                    <p className="text-base md:text-xl text-black/80 mb-12">
                        We're working hard to bring you our new website. Stay tuned for something special.
                    </p>

                    {/* Email Signup */}
                    <div className="mb-8">
                        <h3 className="text-base font-semibold text-black/80 mb-4">Get Notified When We Launch</h3>
                        <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-4 max-w-lg mx-auto">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="flex-grow py-3 px-4 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 duration-150 text-white font-medium py-3 px-6 rounded-lg transition-all"
                            >
                                Notify Me
                            </button>
                        </form>
                    </div>

                    {/* Social Links */}
                    <div className="flex justify-center space-x-6 mt-8">
                        {['facebook', 'twitter', 'instagram', 'linkedin'].map(platform => (
                            <a
                                key={platform}
                                href={`#${platform}`}
                                className="text-white hover:text-pink-300 transition-colors"
                                aria-label={platform}
                            >
                                <div className="w-10 h-10 flex items-center justify-center rounded-full bg-white/20 hover:bg-white/30 transition-all">
                                    {/* You can replace these with actual icons */}
                                    <span className="capitalize text-sm">{platform.charAt(0).toUpperCase()}</span>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ComingSoon;