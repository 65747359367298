import { useState } from 'react'
import MiniLoader from '../../../components/preloader/mini-preloader';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import CustomerNavigation from "../../../components/customer-component/customerNav/nav";
import Footer from "../../../components/home-ui-components/footer";
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.error("Please enter your email");
            return;
        }
        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/send-otp/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                throw new Error("Failed to send OTP");
            }

            // Navigate to /verify and pass the email as a URL param
            navigate(`/verify?email=${encodeURIComponent(email)}`);
        } catch (error) {
            console.error("Error sending OTP:", error);
            alert("Failed to send OTP. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div>
            <CustomerNavigation />
            <div className="flex justify-center bg-blue-600">
                <div className="w-[97%] md:w-[500px]">
                    <div className="flex justify-center ">
                        <p className="text-white text-[30px] font-medium  mt-[100px] mb-[45px] md:mb-[80px]">
                            FreshMclean
                        </p>
                    </div>
                    <div className="mb-[100px]">
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white flex flex-col p-5 rounded-md mx-2">
                                <ToastContainer />

                                <div className="space-y-4">
                                    <p className='text-slate-900 text-center font-medium text-md'>Enter your email to reset your password</p>
                                    <div>
                                        <label className="mb-1 font-medium text-sm block">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Enter your email "
                                            className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="bg-primary-sky text-white py-3 rounded-md text-center mt-3"
                                    disabled={loading}
                                >
                                    {loading ? <MiniLoader /> : "Reset Password"}
                                </button>

                                <hr className="mt-8 mb-4" />

                                <p className="text-center text-slate-600 text-sm font-medium mt-4"><Link className="text-blue-800" to='/login'>Back to Login</Link> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ResetPassword;