import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'
import MiniLoader from "../preloader/mini-preloader";

export default function Footer() {
  const [year, setYear] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${apiUrl}/subscribe`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) throw new Error("Subscription failed");

      toast.success("Subscribed successfully!");
      setEmail("");
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
      console.error("Subscription error:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const isDropdownOpen = (index) => openDropdown === index;

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, [year]);
  return (
    <div className="flex flex-col pt-20 pb-32 lg:px-20 xl:px-52 xs:px-4 bg-black">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="text-white font-helvetica-neue  flex justify-between  md:flex-row xs:flex-col xs:gap-y-10 max-lg:gap-x-16 ">
        <div className="max-md:text-center">
          <p className="font-bold text-2xl mb-4">FreshMClean</p>

          <p className="inline-block max-w-[400px] text-sm">
            {t('home.services.footerText')}
          </p>
          <form onSubmit={handleSubmit} className="mt-10 flex xs:flex-col md:flex-row ">
            <input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-white md:w-1/2 py-2 px-4 rounded-md md:mr-3 xs-full outline-none text-black flex-grow"
            />
            <button className="bg-primary-sky py-2 px-2 rounded-md max-md:mt-4 ml-auto max-md:w-full">
              {loading ? <MiniLoader /> : t('utility.subscribe')}
            </button>
          </form>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <h3 className="block text-xl font-semibold mb-6">Product</h3>
            <Link to='/coming-soon' className="block text-sm mb-3">Benefits</Link>
            <Link to='/coming-soon' className="block text-sm mb-3">Features</Link>
            <Link to='/price' className="block text-sm mb-3">Pricing</Link>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-6">Company</h3>
            <Link to='/about' className="block text-sm mb-3">About</Link>
            <Link to='/coming-soon' className="block text-sm mb-3">Customers</Link>
            <Link to='/faqs' className="block text-sm mb-3">FAQS</Link>
            {/* <Link to='/contact' className="block text-sm mb-3">Contacts</Link> */}
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-6">Use cases</h3>
            <Link to='/coming-soon' className="block text-sm mb-3">For Cleaner</Link>
            <Link to='/coming-soon' className="block text-sm mb-3">For Clients</Link>
            <Link to='/privacy-policy' className="block text-sm mb-3">Privacy Policy</Link>
            <Link to='/terms' className="block text-sm mb-3">Terms of Service</Link>
          </div>
        </div>
      </div>
      <div className="mt-11 text-white flex flex-col">
        <hr className="border-top-2 border-gray-700  w-full inset-0 max-md:hidden" />
        <p className="self-center mt-10">© {year} FreshMClean, Inc. </p>
      </div>
    </div>
  );
}
