import { useState } from 'react'
import MiniLoader from '../../../components/preloader/mini-preloader';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import CustomerNavigation from "../../../components/customer-component/customerNav/nav";
import Footer from "../../../components/home-ui-components/footer";
import { useSearchParams, useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password) {
            toast.error("Please enter a new password");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${apiUrl}/changePassword`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, newPassword: password }),
            });

            if (!response.ok) {
                throw new Error("Failed to change password");
            }

            toast.success("Password changed successfully. You can now log in.");

            setTimeout(() => {
                navigate("/login");
            }, 1500);
        } catch (error) {
            console.error("Error changing password:", error);
            toast.error("Password reset failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <CustomerNavigation />
            <div className="flex justify-center bg-blue-600">
                <div className="w-[97%] md:w-[500px]">
                    <div className="flex justify-center ">
                        <p className="text-white text-[30px] font-medium  mt-[100px] mb-[45px] md:mb-[80px]">
                            FreshMclean
                        </p>
                    </div>
                    <div className="mb-[100px]">
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white flex flex-col p-5 rounded-md mx-2">
                                <ToastContainer />

                                <div className="space-y-4">
                                    <p className='text-slate-900 text-center font-medium text-md'>Enter your new password</p>
                                    <div>
                                        <label className="mb-1 font-medium text-sm block">
                                            New password
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Enter your new password"
                                            className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 w-full"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="bg-primary-sky text-white py-3 rounded-md text-center mt-3"
                                    disabled={loading}
                                >
                                    {loading ? <MiniLoader /> : "Change password"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ChangePassword;