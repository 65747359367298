import Navigation from "../components/nav";
import React, { useState, useEffect } from 'react'
import DateTimeSelector from "../components/home-ui-components/datetime";
import AdditionalServices from "../components/home-ui-components/additionalServices";
import Footer from "../components/home-ui-components/footer";
import Modal from "../components/home-ui-components/bookModal";
import { toast } from 'react-toastify'
import MiniLoader from "../components/preloader/mini-preloader";
import CheckoutForm from "../components/checkoutform";
import { useSearchParams } from 'react-router-dom';

const BookCleaning = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [searchParams] = useSearchParams();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loading, setLoading] = useState();

    const [spaces, setSpaces] = useState(1);
    const [bathrooms, setBathrooms] = useState(1);
    const [cleaningType, setCleaningType] = useState('kitchen');
    const [frequency, setFrequency] = useState('one-time');
    const [frequencies, setFrequencies] = useState([]);

    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [additionalServicesPrice, setAdditionalServicesPrice] = useState(0);
    const [selectedServices, setSelectedServices] = useState([]);

    const [formData, setFormData] = useState({
        city: '',
        street: '',
        streetNumber: '',
        building: '',
        apartmentNumber: '',
        postalCode: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        additionalInfo: ''
    });

    const [clientSecret, setClientSecret] = useState(null);
    const [showCheckout, setShowCheckout] = useState(false);

    // Base price and calculations
    const basePrice = 54.00;
    const spacePrice = 13.60;
    const bathroomPrice = 4.00;
    const kitchenLivingRoomDiscount = 6.00;

    // Calculate price based on selections
    const calculatePrice = () => {
        let price = basePrice;

        // Add price for additional spaces
        price += (spaces - 1) * spacePrice;

        // Add price for additional bathrooms
        price += (bathrooms - 1) * bathroomPrice;

        // Apply kitchen-living room discount if selected
        if (cleaningType === 'kitchen-living') {
            price -= kitchenLivingRoomDiscount;
        }

        // Add price for additional services
        price += additionalServicesPrice;

        return price;
    };

    // Handle additional services changes
    const handleServiceChange = (totalPrice, services) => {
        setAdditionalServicesPrice(totalPrice);
        setSelectedServices(services);
    };

    const estimateDuration = () => {
        let duration = 3;

        // Add time for additional spaces
        duration += (spaces - 1) * 0.5;

        // Add time for additional bathrooms
        duration += (bathrooms - 1) * 0.5;

        // Add time for additional services
        selectedServices.forEach(service => {
            switch (service.id) {
                case 'oven':
                case 'refrigerator':
                    duration += 0.5 * service.count;
                    break;
                case 'extractor':
                case 'kitchen-cabinets':
                    duration += 0.75 * service.count;
                    break;
                case 'window':
                    duration += 0.25 * service.count;
                    break;
                default:
                    duration += 0.3 * service.count;
            }
        });

        return duration;
    };

    // Calculate discount based on frequency
    const calculateDiscount = () => {
        const price = calculatePrice();
        const selectedFrequency = frequencies.find(f => f.frequencyName.toLowerCase().replace(' ', '-') === frequency);
        if (selectedFrequency) {
            return price * (selectedFrequency.frequencyDiscount / 100);
        }
        return 0;
    };

    // Fetch frequencies on component mount
    useEffect(() => {
        const fetchFrequencies = async () => {
            try {
                const response = await fetch(`${apiUrl}/getAllFrequencies`);
                const result = await response.json();
                if (result.success) {
                    setFrequencies(result.data);
                    
                    // Get frequency from URL query parameters
                    const frequencyParam = searchParams.get('frequency');
                    if (frequencyParam) {
                        // Find the matching frequency from the API response
                        const matchingFrequency = result.data.find(
                            f => f.frequencyName.toLowerCase().replace(' ', '-') === frequencyParam
                        );
                        if (matchingFrequency) {
                            setFrequency(frequencyParam);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching frequencies:', error);
            }
        };

        fetchFrequencies();
    }, [apiUrl, searchParams]);

    // Calculate final price with discount
    const finalPrice = calculatePrice() - calculateDiscount();

    // Format price to 2 decimal places
    const formatPrice = (price) => {
        return price.toFixed(2);
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Check if user is logged in
        const token = localStorage.getItem("token");
        if (!token) {
            setShowLoginModal(true);
            setLoading(false);
            return;
        }

        // Format date and time from selectedDateTime
        let formattedDate = '';
        let formattedTime = '';
        if (selectedDateTime) {
            formattedDate = selectedDateTime.toISOString().split('T')[0];
            const hours = selectedDateTime.getHours().toString().padStart(2, '0');
            const minutes = selectedDateTime.getMinutes().toString().padStart(2, '0');
            formattedTime = `${hours}:${minutes}`;
        }

        // Format the data according to the specified structure
        const formattedData = {
            apartment: {
                space: Number(spaces),
                bathroom: Number(bathrooms)
            },
            entireApartment: cleaningType === 'kitchen' ? 'Kitchen' : 'Kitchen-living room',
            frequency: frequency === 'one-time' ? 'One time' : frequency,
            date: formattedDate,
            time: formattedTime,
            additionalRole: selectedServices.map(service => ({
                roleId: service._id,
                quantity: Number(service.count)
            })),
            address: {
                city: formData.city,
                street: formData.street,
                streetNumber: formData.streetNumber,
                building: formData.building,
                apartmentNumber: formData.apartmentNumber,
                postalCode: formData.postalCode
            },
            contact: {
                fullName: formData.fullName,
                email: formData.email,
                phone: formData.phoneNumber
            },
            additionalInformation: formData.additionalInfo,
            totalPrice: Math.round(Number(finalPrice) * 100)
        };

        console.log('Formatted data being sent:', formattedData);

        try {
            // Step 1: Create cleaning order
            const response = await fetch(`${apiUrl}/createCleaning`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formattedData)
            });

            const data = await response.json();
            console.log(data)

            if (!data.success) {
                throw new Error(data.message || 'Failed to create cleaning order');
            }

            // Step 2: Create payment intent
            const paymentResponse = await fetch(`${apiUrl}/createPaymentIntent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    amount: Math.round(Number(finalPrice) * 100),
                    currency: 'eur',
                    description: 'Cleaning Service Booking',
                    userId: data.data._id
                })
            });
            console.log(data.data.totalPrice)
            const paymentData = await paymentResponse.json();

            console.log(paymentData)

            if (!paymentData.success) {
                throw new Error(paymentData.message || 'Failed to create payment intent');
            }

            // Set client secret and show checkout form
            setClientSecret(paymentData.clientSecret);
            setShowCheckout(true);

        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message || 'An error occurred while processing your request');
        } finally {
            setLoading(false);
        }
    };

    // Function to open authentication pages in new tabs
    const openAuthPage = (page) => {
        // Save form data to sessionStorage
        const bookingData = {
            formData,
            spaces,
            bathrooms,
            cleaningType,
            frequency,
            selectedDateTime,
            additionalServicesPrice,
            selectedServices
        };
        sessionStorage.setItem('bookingData', JSON.stringify(bookingData));
        // Navigate in the same tab instead of opening a new one
        window.location.href = `/${page}`;
    };

    // Load saved booking data on component mount
    useEffect(() => {
        const savedBookingData = sessionStorage.getItem('bookingData');
        if (savedBookingData) {
            try {
                const {
                    formData: savedFormData,
                    spaces: savedSpaces,
                    bathrooms: savedBathrooms,
                    cleaningType: savedCleaningType,
                    frequency: savedFrequency,
                    selectedDateTime: savedDateTime,
                    additionalServicesPrice: savedServicesPrice,
                    selectedServices: savedServices
                } = JSON.parse(savedBookingData);
                
                // Only set values if they exist
                if (savedFormData) setFormData(savedFormData);
                if (savedSpaces) setSpaces(savedSpaces);
                if (savedBathrooms) setBathrooms(savedBathrooms);
                if (savedCleaningType) setCleaningType(savedCleaningType);
                if (savedFrequency) setFrequency(savedFrequency);
                if (savedDateTime) setSelectedDateTime(savedDateTime);
                if (savedServicesPrice) setAdditionalServicesPrice(savedServicesPrice);
                if (savedServices) setSelectedServices(savedServices);

                // Delete the saved data after loading
                sessionStorage.removeItem('bookingData');
            } catch (error) {
                console.error('Error loading saved booking data:', error);
                // If there's an error, clear the invalid data
                sessionStorage.removeItem('bookingData');
            }
        }
    }, []);

    // Close the login modal
    const closeLoginModal = () => {
        setShowLoginModal(false);
    };

    return (
        <div>
            <Navigation />

            <div className="max-w-7xl mx-auto px-4 pt-6">
                <form onSubmit={handleSubmit} className="grid gap-8 grid-cols-1 md:grid-cols-[1fr,24rem] 2xl:grid-cols-[1fr,28rem]">
                    {/* Left column - Form */}
                    <div className="bg-white rounded-lg shadow-sm">
                        <div className="py-6 px-0 md:px-6">
                            <h1 className="text-2xl font-bold mb-6">Apartment cleaning Berlin</h1>

                            {/* Client type selection */}
                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
                                <button type="button" className="flex-1 bg-blue-600 text-white py-3 px-4 rounded-md font-medium">
                                    For private individuals
                                </button>
                                {/* <button className="flex-1 bg-white border border-gray-300 py-3 px-4 rounded-md font-medium">
                                    For companies
                                </button> */}
                            </div>

                            {/* Apartment configuration */}
                            <div className="mb-8">
                                <h2 className="text-lg text-gray-500 font-medium mb-6">YOUR APARTMENT</h2>

                                {/* Spaces counter */}
                                <div className="grid grid-cols-2 gap-4 mb-6">
                                    <div className="border border-gray-200 rounded-md p-4">
                                        <div className="flex items-center justify-between">
                                            <button type="button"
                                                className="w-8 h-8 flex items-center justify-center text-lg bg-gray-100 rounded-md"
                                                onClick={() => setSpaces(Math.max(1, spaces - 1))}
                                            >
                                                -
                                            </button>
                                            <span className="text-lg font-medium">{spaces} Space{spaces !== 1 ? 's' : ''}</span>
                                            <button type="button"
                                                className="w-8 h-8 flex items-center justify-center text-lg bg-gray-100 rounded-md"
                                                onClick={() => setSpaces(spaces + 1)}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>

                                    {/* Bathrooms counter */}
                                    <div className="border border-gray-200 rounded-md p-4">
                                        <div className="flex items-center justify-between">
                                            <button type="button"
                                                className="w-8 h-8 flex items-center justify-center text-lg bg-gray-100 rounded-md"
                                                onClick={() => setBathrooms(Math.max(1, bathrooms - 1))}
                                            >
                                                -
                                            </button>
                                            <span className="text-lg font-medium">{bathrooms} bathroom{bathrooms !== 1 ? 's' : ''}</span>
                                            <button type="button"
                                                className="w-8 h-8 flex items-center justify-center text-lg bg-gray-100 rounded-md"
                                                onClick={() => setBathrooms(bathrooms + 1)}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <p className="text-gray-500 text-sm mb-6">* Complete cleaning of the entire apartment, kitchen, toilet and bathroom</p>

                                {/* Kitchen options */}
                                <div className="flex items-center gap-4 mb-6">
                                    <div className="flex items-center gap-2">
                                        <div
                                            className={`w-6 h-6 border rounded flex items-center justify-center cursor-pointer ${cleaningType === 'kitchen' ? 'bg-white border-blue-600' : 'bg-gray-100 border-gray-200'}`}
                                            onClick={() => setCleaningType('kitchen')}
                                        >
                                            {cleaningType === 'kitchen' && <span className="text-blue-600">✓</span>}
                                        </div>
                                        <span className="font-medium">Kitchen</span>
                                    </div>

                                    <span className="text-gray-400">Or</span>

                                    <div className="flex items-center gap-2">
                                        <div
                                            className={`w-6 h-6 border rounded flex items-center justify-center cursor-pointer ${cleaningType === 'kitchen-living' ? 'bg-white border-blue-600' : 'bg-gray-100 border-gray-200'}`}
                                            onClick={() => setCleaningType('kitchen-living')}
                                        >
                                            {cleaningType === 'kitchen-living' && <span className="text-blue-600">✓</span>}
                                        </div>
                                        <span className="font-medium">kitchen-living room</span>
                                    </div>

                                    <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded text-sm font-medium">
                                        -6.00 EUR
                                    </span>
                                </div>

                                {/* Cleaning frequency */}
                                <div className="mt-8">
                                    <h3 className="font-medium mb-3">Cleaning frequency</h3>
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                                        {frequencies.map((freq) => (
                                            <div
                                                key={freq._id}
                                                onClick={() => setFrequency(freq.frequencyName.toLowerCase().replace(' ', '-'))}
                                                className={`border rounded-md p-3 cursor-pointer ${frequency === freq.frequencyName.toLowerCase().replace(' ', '-') ? 'border-blue-600 bg-blue-50' : 'border-gray-200'}`}
                                            >
                                                <div className="font-medium">{freq.frequencyName}</div>
                                                <div className="text-sm text-gray-600">Discount: {freq.frequencyDiscount}%</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Select date */}
                                <DateTimeSelector
                                    onDateTimeSelected={(dateTime) => setSelectedDateTime(dateTime)}
                                    initialDateTime={selectedDateTime}
                                />

                                {/* Additional Roles */}
                                <h2 className="text-lg text-gray-500 font-medium mb-6">ADDITIONAL ROLES</h2>
                                <AdditionalServices 
                                    onServiceChange={handleServiceChange} 
                                    initialServices={selectedServices}
                                />

                                {/* Address and Contact*/}
                                <div className='mt-8'>
                                    {/* Address Section */}
                                    <h2 className="text-lg text-gray-500 font-medium mb-6">ENTER YOUR ADDRESS</h2>

                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">City</label>
                                            <input
                                                type="text"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter city"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Street</label>
                                            <input
                                                type="text"
                                                name="street"
                                                value={formData.street}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter street name"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Street Number</label>
                                            <input
                                                type="text"
                                                name="streetNumber"
                                                value={formData.streetNumber}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="e.g. 123"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Building</label>
                                            <input
                                                type="text"
                                                name="building"
                                                value={formData.building}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Building name/number (optional)"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Apartment Number</label>
                                            <input
                                                type="text"
                                                name="apartmentNumber"
                                                value={formData.apartmentNumber}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="e.g. 4B"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Postal Code</label>
                                            <input
                                                type="text"
                                                name="postalCode"
                                                value={formData.postalCode}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter postal code"
                                            />
                                        </div>
                                    </div>

                                    {/* Divider between Address and Contact */}
                                    <hr className="my-8 border-t border-gray-300" />

                                    {/* Contact Section */}
                                    <h2 className="text-lg text-gray-500 font-medium mb-6">CONTACT INFORMATION</h2>

                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                                            <input
                                                type="text"
                                                name="fullName"
                                                value={formData.fullName}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter your full name"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="your@email.com"
                                            />
                                        </div>

                                        <div className='input-group mb-4'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                                            <input
                                                type="tel"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="+1 (123) 456-7890"
                                            />
                                        </div>

                                        <div className='input-group mb-4 col-span-full'>
                                            <label className="block text-sm font-medium text-gray-700 mb-1">Additional Information</label>
                                            <textarea
                                                name="additionalInfo"
                                                value={formData.additionalInfo}
                                                onChange={handleInputChange}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-24"
                                                placeholder="Special instructions or information about your order..."
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-8 hidden md:block'>
                                    <button type='submit' className="w-full bg-blue-600 text-white py-5 rounded-lg text-lg font-semibold">
                                        {loading ? <MiniLoader /> : (
                                            <span>
                                                Order now for {formatPrice(finalPrice)} EUR {calculateDiscount() > 0 && (
                                                    <span className="text-gray-300 line-through ml-2">{formatPrice(calculatePrice())} EUR</span>
                                                )}
                                            </span>
                                        )}
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right column - Summary card */}
                    <div className="relative">
                        <div className="sticky top-4">
                            <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
                                <h2 className="font-semibold text-xl mb-3">
                                    Cleaning of the apartment with {spaces} living room and {bathrooms} bathroom{bathrooms !== 1 ? 's' : ''},
                                    {cleaningType === 'kitchen' ? ' kitchen' : ' kitchen-living room'}
                                </h2>

                                <div className="flex items-center gap-2 bg-yellow-50 p-3 rounded-md mb-4">
                                    <div className="w-8 h-8 rounded-full flex items-center justify-center text-yellow-600">
                                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" fill="none">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                                        </svg>
                                    </div>
                                    <p className="text-sm">
                                        Our partners bring all necessary cleaning supplies, tools and vacuum cleaners
                                    </p>
                                </div>

                                <p className="mb-4">Duration approx. <span className="font-medium">{Math.floor(estimateDuration())} hours {Math.round((estimateDuration() % 1) * 60)} minutes</span></p>

                                <div className="flex gap-2 mb-6">
                                    <div className="flex-1">
                                        <input
                                            type="text"
                                            placeholder="Enter promotion code"
                                            className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm"
                                        />
                                    </div>
                                    <button type="button" className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium">
                                        Apply
                                    </button>
                                </div>

                                {/* <div className="flex items-center justify-between mb-2">
                                    <span className="text-sm font-medium">Referral program</span>
                                    <span className="text-sm text-blue-600">How does it work?</span>
                                </div> */}

                                <div className="border-t border-gray-200 pt-4 mt-4">
                                    <div className="flex items-end justify-between mb-1">
                                        <span className="text-lg">To pay:</span>
                                        <div className="text-right">
                                            <span className="text-2xl font-bold">{formatPrice(finalPrice)} EUR</span>
                                            {calculateDiscount() > 0 && (
                                                <span className="text-gray-400 line-through ml-2">{formatPrice(calculatePrice())} EUR</span>
                                            )}
                                        </div>
                                    </div>

                                    {calculateDiscount() > 0 && (
                                        <p className="text-sm text-gray-600">*Your {frequencies.find(f => f.frequencyName.toLowerCase().replace(' ', '-') === frequency)?.frequencyDiscount}% discount applied</p>
                                    )}

                                    <button type='submit' className="w-full bg-blue-600 text-white py-3 rounded-lg text-sm font-medium mt-4">
                                        {loading ? <MiniLoader /> : "Order now"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Login Modal */}
            {showLoginModal && (
                <Modal onClose={closeLoginModal}>
                    <div className="p-6">
                        <h2 className="text-2xl font-bold mb-4">Login Required</h2>
                        <p className="mb-6">You need to be logged in to complete your booking. Please login or create an account to continue.</p>

                        <div className="flex justify-between gap-4">
                            <button type="button"
                                onClick={() => openAuthPage('login')}
                                className="flex-1 py-2 bg-blue-600 text-white font-medium rounded hover:bg-blue-700 transition-colors"
                            >
                                Login
                            </button>
                            <button type="button"
                                onClick={() => openAuthPage('signup')}
                                className="flex-1 py-2 bg-black text-white font-medium rounded hover:bg-gray-800 transition-colors"
                            >
                                Create Account
                            </button>
                        </div>

                        <div className="mt-4 text-center">
                            <button type="button"
                                onClick={closeLoginModal}
                                className="text-gray-600 hover:text-gray-800"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            {showCheckout && clientSecret && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full">
                        <CheckoutForm 
                            clientSecret={clientSecret} 
                            onClose={() => setShowCheckout(false)}
                        />
                    </div>
                </div>
            )}

            <Footer />
        </div>
    )
}

export default BookCleaning;