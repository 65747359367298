import React, { useState, useEffect } from 'react';

const AdditionalServices = ({ onServiceChange, initialServices }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  // Fetch services on component mount only
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${apiUrl}/getAllRoles`);
        const result = await response.json();
        if (result.success) {
          // Add count property to each service
          const servicesWithCount = result.data.map(service => {
            // If we have initial services and haven't initialized yet, find matching service and use its count
            const initialService = !isInitialized && initialServices?.find(s => s._id === service._id);
            return {
              ...service,
              count: initialService ? initialService.count : 0,
              regularPrice: service.rolePrice * 1.25 // Calculate regular price as 25% more than role price
            };
          });
          setServices(servicesWithCount);
          
          // If we have initial services and haven't initialized yet, update the total price
          if (!isInitialized && initialServices?.length > 0) {
            const totalAdditionalPrice = servicesWithCount.reduce((sum, service) => {
              return sum + (service.rolePrice * service.count);
            }, 0);
            onServiceChange(totalAdditionalPrice, servicesWithCount.filter(s => s.count > 0));
            setIsInitialized(true);
          }
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [apiUrl]); // Only depend on apiUrl

  // Function to increment service count
  const incrementService = (id) => {
    const updatedServices = services.map(service => 
      service._id === id ? { ...service, count: service.count + 1 } : service
    );
    setServices(updatedServices);
    updateTotalPrice(updatedServices);
  };

  // Function to decrement service count
  const decrementService = (id) => {
    const updatedServices = services.map(service => 
      service._id === id && service.count > 0 ? { ...service, count: service.count - 1 } : service
    );
    setServices(updatedServices);
    updateTotalPrice(updatedServices);
  };

  // Calculate additional services total price
  const updateTotalPrice = (updatedServices) => {
    const totalAdditionalPrice = updatedServices.reduce((sum, service) => {
      return sum + (service.rolePrice * service.count);
    }, 0);
    
    // Notify parent component about the change
    onServiceChange(totalAdditionalPrice, updatedServices.filter(s => s.count > 0));
  };

  if (loading) {
    return <div className="text-center py-4">Loading services...</div>;
  }

  return (
    <div className="additional-services my-4">
      {/* <h3 className="text-lg font-semibold mb-3">Additional services</h3> */}
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {services.map(service => (
          <div key={service._id} className="service-item border rounded-lg p-4 flex flex-col h-full">
            <div className="flex-grow">
              <div className="font-medium">{service.roleName}</div>
              <div className="flex items-center">
                <span className="font-bold text-blue-600">{service.rolePrice.toFixed(2)} EUR</span>
              </div>
            </div>

            {service.count > 0 ? (
              <div className="counter-controls mt-2 flex items-center justify-center border rounded">
                <button type="button"
                  className="px-4 py-2 text-lg font-bold text-blue-600"
                  onClick={() => decrementService(service._id)}
                >
                  -
                </button>
                <span className="px-4 py-2">{service.count}</span>
                <button type="button"
                  className="px-4 py-2 text-lg font-bold text-blue-600"
                  onClick={() => incrementService(service._id)}
                >
                  +
                </button>
              </div>
            ) : (
              <button type="button"
                className="mt-2 py-2 w-full bg-black text-white rounded font-medium transition-colors"
                onClick={() => incrementService(service._id)}
              >
                Add
              </button>
            )}
          </div>
        ))}
      </div>
      
      {services.some(service => service.count > 0) && (
        <div className="selected-services mt-4 p-4 bg-gray-100 rounded">
          <h4 className="font-medium mb-2">Selected additional services:</h4>
          <ul>
            {services.filter(service => service.count > 0).map(service => (
              <li key={service._id} className="flex justify-between items-center py-1">
                <span>{service.roleName} (x{service.count})</span>
                <span className="font-semibold">{(service.rolePrice * service.count).toFixed(2)} EUR</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdditionalServices;