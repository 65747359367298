import React from "react";
import Navigation from "../components/nav";
import Footer from "../components/home-ui-components/footer";

const TermsOfService = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <Navigation />

            <div className="max-w-3xl mx-auto px-4 py-12">
                <header className="mb-10 text-center">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4">General Terms and Conditions (GTC) of FreshMClean</h1>
                    <p className="text-gray-600">Effective Date: March 16, 2025</p>
                </header>

                <div className="bg-white rounded-lg shadow-sm p-8 mb-8">
                    <p className="text-gray-600 text-sm md:text-base"><span className="font-semibold">Company name:</span> FreshMClean</p>
                    <p className="text-gray-600 text-sm md:text-base"><span className="font-semibold">Slogan:</span> Convenience at your doorstep!</p>
                    <p className="text-gray-600 text-sm md:text-base"><span className="font-semibold">Location:</span> Berlin, Germany</p>
                    <p className="text-gray-700 my-4 text-sm md:text-base">
                        Welcome to FreshMClean! These General Terms and Conditions (GTC) govern the use of our cleaning services in Berlin.
                    </p>

                    {/* Scope of Services */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">1. Scope of Services</h2>
                        <p className="text-gray-700 mb-4 text-sm md:text-base">
                            FreshMClean provides professional cleaning services for residential, office, and educational spaces within Berlin. Our services include but are not limited to:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">Home cleaning.</li>
                            <li className="text-sm md:text-base">Office cleaning.</li>
                            <li className="text-sm md:text-base">School cleaning.</li>
                        </ul>
                        <p className="text-gray-700 mt-4 text-sm md:text-base">
                            We prioritize eco-friendly products and ensure the highest quality standards.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Booking and Cancellation */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">2. Booking and Cancellation</h2>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">
                                <span className="font-semibold">Booking Process:</span> Clients can book our services via our website, phone, or email. Confirmation of booking is required to secure the appointment.
                            </li>
                            <li className="text-sm md:text-base">
                                <span className="font-semibold">Cancellations:</span> Clients must provide at least 24 hours’ notice for cancellations. Cancellations made with less than 24 hours’ notice may incur a cancellation fee of <span className="font-semibold">[X% of service fee]</span>.
                            </li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Payment Terms */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">3. Payment Terms</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            Accepted payment methods include bank transfer, credit card, and digital payment platforms.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Client Responsibilities */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">4. Client Responsibilities</h2>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">Clients are responsible for providing access to the property at the agreed time.</li>
                            <li className="text-sm md:text-base">FreshMClean is not liable for delays or incomplete services due to restricted access or unsafe conditions.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Liability and Damages */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">5. Liability and Damages</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            FreshMClean takes utmost care during cleaning. However, in the unlikely event of damage caused by our staff, clients must notify FreshMClean within 48 hours.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Eco-Friendly Commitment */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">6. Eco-Friendly Commitment</h2>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">FreshMClean uses environmentally safe and non-toxic products for all services.</li>
                            <li className="text-sm md:text-base">Clients may request specific products, provided they align with FreshMClean’s sustainability standards.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Confidentiality */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">7. Confidentiality</h2>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">FreshMClean values client privacy and ensures that all personal data is handled in compliance with GDPR regulations.</li>
                            <li className="text-sm md:text-base">Client information will not be shared with third parties without prior consent.</li>
                        </ul>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Termination of Services */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">8. Termination of Services</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            FreshMClean reserves the right to terminate services due to non-payment, unsafe working conditions, or breach of terms by the client.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Dispute Resolution */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">9. Dispute Resolution</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            Any disputes arising from these terms will be resolved amicably or through legal proceedings in Berlin. The governing law is German law.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Amendments to Terms */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">10. Amendments to Terms</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            FreshMClean reserves the right to update these terms and conditions. Clients will be notified of any changes before they take effect.
                        </p>
                    </section>

                    <div className="border-t border-gray-200 my-8"></div>

                    {/* Contact Information */}
                    <section className="mb-10">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">11. Contact Us</h2>
                        <p className="text-gray-700 text-sm md:text-base">
                            For any questions or clarifications regarding these terms, please contact us:
                        </p>
                        <ul className="list-disc pl-5 space-y-2 text-gray-700">
                            <li className="text-sm md:text-base">Email: info@freshmclean.com</li>
                            <li className="text-sm md:text-base">Phone: [Insert Phone Number]</li>
                        </ul>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default TermsOfService;