import Navigation from "../components/nav";
import comfyRoom from "../assests/images/living-room.png";
import Carousel2 from '../assests/images/carousel2.jpg';
import Carousel3 from '../assests/images/carousel3.jpg';
import Orb from '../assests/images/orb.png';
import RenderServices from "../components/home-ui-components/renderServices";
import Benefits from "../components/home-ui-components/renderBenefits";
import Rectangle from "../assests/images/rectangle.png";
import Undraw from "../assests/undraw-clean.svg";
import Pricings from "../components/home-ui-components/pricings";
import Contact from "../components/home-ui-components/contact";
import Blog from "../components/home-ui-components/blog";
import Partners from "../components/home-ui-components/partners";
// import FAQs from "../components/home-ui-components/faqs";
import Footer from "../components/home-ui-components/footer";
import Carousel from "../components/carousel";
import Testimonials from "../components/home-ui-components/testimonials";
// import BgPng from "../assests/images/bg.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { ReactTyped } from "react-typed";
import { useTranslation } from "react-i18next";

export default function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const handleGetStarted = async () => {
    if (!token) {
      navigate('/login');
    } else {
      navigate('/dashboard');
    }
  };

  // if (token) {
  //   console.log("Logged in")
  // } else {
  //   console.log("not logged in")
  // }

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const images = [
    comfyRoom,
    Carousel2,
    Carousel3,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="w-full relative overflow-hidden">
      <Link to="https://wa.me/+4915216901919"
        target="_blank" className='fixed right-3 bottom-3 z-40 p-1 bg-green-400 rounded-full border-white border-4'>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp Logo"
          className="w-[50px] md:w-[70px] rounded-full"
        />
      </Link>
      <div>
        <Navigation />
      </div>
      <div className="text-center bg-white pt-10 pb-28 mb-0 z-10 relative">
        <div className="orb absolute top-3 left-6">
          <img src={Orb} className="" alt="" />
        </div>
        <span
          data-aos="fade-up"
          className="px-2.5 py-2 text-xs font-medium uppercase bg-blue-100 text-blue-600 rounded-lg mt-10 inline-block"
        >
          freshmclean
        </span>

        <h1
          data-aos="fade-up"
          data-aos-delay="300"
          className="text-3xl md:text-4xl lg:text-6xl font-medium mt-4 !leading-[3rem] md:!leading-[5rem] font-hel w-[97%] md:w-[55%] mx-auto"
        >
          {t('home.hero.title')}
          <span
            data-aos="fade-up"
            data-aos-delay="900"
            className="px-3 py-[1px] text-white bg-blue-700 rounded-lg"
          >
            fingertip
          </span>
        </h1>

        <div className="inline-block mt-4" data-aos="fade-up" data-aos-delay="300">
          {/* {!token ? (
            <Link to="/book">
              <button className="mt-6 px-4 py-3 text-white bg-black hover:bg-blue-800 duration-150 rounded-lg text-sm md:text-base font-medium">
                {t('utility.bookACleaning')}
              </button>
            </Link>
          ) : (
            <Link to="/dashboard">
              <button className="mt-6 px-4 py-3 text-white bg-black hover:bg-blue-800 duration-150 rounded-lg text-sm md:text-base font-medium">
                {t('home.hero.proceedToDashboard')}
              </button>
            </Link>
          )} */}

          <Link to="/book-cleaning">
            <button className="mt-6 px-4 py-3 text-white bg-black hover:bg-blue-800 duration-150 rounded-lg text-sm md:text-base font-medium">
              {t('utility.bookACleaning')}
            </button>
          </Link>
        </div>
      </div>

      <div
        className="relative bg-white pb-10 -mt-2"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <div className="overflow-hidden md:rounded-xl w-full md:w-[97%] mx-auto my-2 h-[350px] sm:h-[350px] md:h-[650px]">
          <div
            className="flex transition-transform duration-700 ease-in-out h-full w-full"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-full h-full object-cover flex-shrink-0"
                alt={`Slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>

      {/* <div
        className="flex items-center bg-primary-bg justify-center max-lg:hidden mt-16 py-16"
        data-aos="fade-up"
      >
        <RenderServices />
      </div> */}

      {/* <div
        className="lg:hidden text-black xl:px-52 lg:px-32 md:px-16 sm:px-8 xs:px-4 xs:-mx-8"
        data-aos="fade-up"
      >
        <Carousel />
      </div> */}

      {/* <div className="text-bg w-full h-[400px] relative flex items-center justify-center px-4 md:px-6 lg:px-24 mb-0">
        <h1 className="text-2xl md:text-4xl lg:text-5xl text-center text-white">
          <span className="mr-2">We are</span>
          <span className="text-blue-500 font-medium">
            <ReactTyped
              strings={[
                " dedicated to spotless spaces.",
                " here to make your life easier.",
                " FreshMclean.",
                " your ultimate solution to house cleaning.",
                " experts in making spaces shine.",
              ]}
              typeSpeed={80}
              backSpeed={40}
              backDelay={3000}
              loop
            />
          </span>
        </h1>
      </div> */}

      <div className="px-6 md:px-12 lg:px-24 py-8 md:py-20 grid gap-8 grid-cols-1 md:grid-cols-2 bg-white">
        <div>
          <h1
            data-aos="fade-up"
            className="text-3xl md:text-4xl lg:text-5xl md:pt-10"
          >
            <span className="mr-2">{t('home.services.weAre')}</span>
            <span className="font-medium text-blue-600">
              <ReactTyped
                strings={[
                  " professionals.",
                  " spotless perfection.",
                  " FreshMclean.",
                  " trustworthy.",
                  " here to help.",
                  " always ready.",
                ]}
                typeSpeed={80}
                backSpeed={40}
                backDelay={3000}
                loop
              />
            </span>
          </h1>

          <p
            data-aos="fade-up"
            className="font-normal text-sm md:text-base text-gray-500 mt-4 w-[80%] md:w-[90%]"
          >
            {t('home.services.experienceText')}
          </p>

          <div className="inline-block" data-aos="fade-up">
            {!token ? (
              <Link to="/signup">
                <button className="mt-6 px-4 py-2.5 text-white bg-black hover:bg-blue-800 duration-150 rounded-lg text-sm font-medium">
                  {t('utility.createAccount')}
                </button>
              </Link>
            ) : (
              <Link to="/dashboard">
                <button className="mt-6 px-4 py-2.5 text-white bg-black hover:bg-blue-800 duration-150 rounded-lg text-sm font-medium">
                  {t('utility.openYourDashboard')}
                </button>
              </Link>
            )}
          </div>
        </div>

        <div data-aos="fade-up" data-aos-delay="500">
          <img src={Undraw} alt="undraw cleaner" />
        </div>
      </div>

      <div className="bg-primary-benefit flex flex-col justify-center items-center py-10 font-helvetica-neue">
        <p className="mb-20 text-indigo-950 font-semibold uppercase text-lg">{t('utility.benefits')}</p>
        <div
          className="flex justify-center items-center xs:px-4 sm:px-0"
          data-aos="fade-up"
        >
          <Benefits />
        </div>
        {/* <button className="bg-primary-sky text-white px-6 py-2 mt-10 rounded-md font-helvetica-neue text-lg">
          Book a Cleaning
        </button> */}
      </div>
      <div
        className="flex relative bg-indigo-950 h-auto md:h-[1000px] py-4"
        style={{ backgroundRepeat: "no-repeat", backgroundSize: "100%" }}
      >
        <div className="text-white w-[550px] md:pl-10 max-sm:w-[570px] lg:pt-32 lg:pl-20 sm:pl-5 sm:pt-10 xs:pt-6 xs:pl-3">
          <p
            className="text-sm mb-2 font-medium uppercase max-sm:text-sm text-gray-400"
            data-aos="fade-up"
          >
            {t('home.services.howItWorks')}
          </p>
          <div className="w-3/4 max-sm:w-full mb-4">
            <p className="font-bold text-3xl max-sm:text-xl" data-aos="fade-up">
              {t('home.services.innerWorking')}
            </p>
          </div>
          <p className="text-md mt-2" data-aos="fade-up">
            {t('home.services.cleanText')}
          </p>
          <Link
            data-aos="fade-up"
            className="bg-primary-sky inline-block text-white hover:bg-white hover:text-blue-600 text-md px-6 py-3 font-medium mt-10 rounded-lg duration-300"
            // onClick={handleGetStarted}
            to='/book-cleaning'
          >
            {t('utility.bookACleaning')}
          </Link>
        </div>

        <div className="absolute right-0 sm:bottom-0 lg:bottom-28 xs:bottom-0 hidden md:flex">
          <div className="text-black align-bottom self-center sm:w-[350px] xs:w-[100%] relative xs:left-52 xs:top-8  sm:left-96 lg:top-16 md:left-96 z-50 w-full">
            <p className="text-xl font-semibold" data-aos="fade-up">
              {t('home.services.bookYourCleaning')}
            </p>
            <p
              style={{ color: "#616161" }}
              className="xs:text-sm mt-2"
              data-aos="fade-up"
            >
              {t('home.services.chooseBooking')}
            </p>
          </div>
          <img
            src={Rectangle}
            alt=""
            className="relative sm:top-16 sm:w-[500px] sm:h-[500px] lg:w-[800px] lg:h-[800px] md:w-[600px] md:h-[600px] xs:h-[600px]  xs:w-[750px] xs:mb-20 lg:mb-0 xs:top-20"
          />
        </div>
      </div>

      <div className="bg-blue-300 px-3 py-16 block md:hidden">
        <p className="text-xl font-semibold" data-aos="fade-up">
          {t('home.services.bookYourCleaning')}
        </p>
        <p className="xs:text-sm mt-2 text-gray-800" data-aos="fade-up">
          {t('home.services.chooseBooking')}
        </p>
      </div>

      <div className="" data-aos="fade-up">
        <Pricings />
      </div>
      <div style={{ backgroundColor: "#F4F8FA" }}>
        <Testimonials />
      </div>
      <div className="w-full bg-white">
        <Contact />
      </div>
      {/* <div className="bg-gray-50 w-full px-4">
        <Blog />
      </div> */}
      <div className="bg-white">
        <Partners />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}
